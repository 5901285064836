import { Button, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import jobBackgroundImage from "../assets/jobs_background_image.webp";
import { useTranslation } from "react-i18next";

const Body = () => {
  const { t } = useTranslation();

  return (
    <Stack
      position="relative"
      height={"700px"}
      width="100%"
      overflow="hidden"
      backgroundImage={jobBackgroundImage}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      justifyContent="center"
      spacing={{ base: 4, md: 8 }}
      p={{ base: "1em", md: "1em 10em 1em 10em" }}
    >
      <Heading
        color="whiteColor"
        fontSize={{ base: "2xl", md: "4xl" }}
        fontWeight="bold"
      >
        {t("home_slide_title")}
      </Heading>
      <Text
        color="whiteColor"
        fontSize={{ base: "xl", md: "2xl" }}
        fontWeight="bold"
      >
        {t("home_slide_description")}
      </Text>
      <HStack spacing={4}>
        <Button colorScheme="red" as="a" href="/career" target="_blank">
          {t("apply")}
        </Button>
        <Button
          colorScheme="whiteAlpha"
          as="a"
          href="/positions"
          target="_blank"
        >
          {t("positions_button")}
        </Button>
      </HStack>
    </Stack>
  );
};

export default Body;
