import React from "react";
import { Button, Heading, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { theme } from "../utils/theme";
import { useTranslation } from "react-i18next";
import { positionsData } from "../utils/consts";
import ArrorUpRightIcon from "../assets/arror-up-right.svg";
import { TimeIcon } from "@chakra-ui/icons";

const PositionList = () => {
  const { t } = useTranslation();

  return (
    <Stack marginTop={{ base: "58px", md: "90px" }}>
      <Stack
        bg={theme.colors.positionPageHeaderBG}
        color={theme.colors.whiteColor}
        p={4}
        h={{ base: "calc(300px - 58px)", md: "calc(300px - 90px)" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Heading
          as={"h1"}
          fontSize={{ base: "2xl", md: "48px" }}
          fontWeight="bold"
        >
          {t("position_list_header_title")}
        </Heading>
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        m={"5em 0"}
        spacing={8}
      >
        {positionsData.map((position) => (
          <HStack
            key={position.id}
            w={"60%"}
            bg={theme.colors.positionPageCardBG}
            color={theme.colors.baseBlackColor}
            p={5}
            borderRadius={10}
            justifyContent={"space-between"}
            cursor="pointer"
            onClick={async () => {
              await localStorage.setItem("selectedJobName", position.job_key);
              window.location.href = "/career";
            }}
          >
            <HStack>
              <Stack
                w={"40px"}
                h={"40px"}
                justifyContent={"center"}
                alignItems={"center"}
                background={theme.colors.whiteColor}
                borderRadius={10}
              >
                <Image
                  src={ArrorUpRightIcon}
                  alt="arrow-up-right"
                  w={"20px"}
                  h={"20px"}
                />
              </Stack>
              <Text
                fontSize={"2xl"}
                fontWeight="bold"
                textTransform={"capitalize"}
              >
                {position.job_name}
              </Text>
            </HStack>
            <HStack>
              <Text
                fontSize={"14px"}
                textTransform={"uppercase"}
                color={theme.colors.positionPageCardTypeColor}
              >
                {t(position.type)}
              </Text>

              <Stack
                w={"40px"}
                h={"40px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={10}
              >
                <TimeIcon />
              </Stack>
            </HStack>
          </HStack>
        ))}
        <Text>{t("position_list_header_description")}</Text>
        <Button
          colorScheme="red"
          borderRadius={10}
          p={5}
          as={"a"}
          href="/career"
          target="_blank"
          maxW={"200px"}
        >
          {t("get_in_touch")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default PositionList;
