import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "../pages/HomePage";
import Careerpage from "../pages/CareerPage";
import Contactus from "../pages/ContactUs";
import About from "../pages/About";
import SoftwareDev from "../pages/service_Pages/SoftwareDev ";
import WebSolution from "../pages/service_Pages/WebSolution";
import Optimization from "../pages/service_Pages/Optimization ";
import SslService from "../pages/service_Pages/SslService ";
import EmailSolution from "../pages/service_Pages/EmailSolution ";
import AndroidApp from "../pages/service_Pages/AndroidApp";
import IosApp from "../pages/service_Pages/IosApp";
import Ecommerce from "../pages/service_Pages/Ecommerce";
import CorporateId from "../pages/service_Pages/CorporateId";
import SocialMedia from "../pages/service_Pages/SocialMedia ";
import GoogleAds from "../pages/service_Pages/GoogleAds";
import GoogleBusiness from "../pages/service_Pages/GoogleBusiness";
import SupportCare from "../pages/service_Pages/SupportCare ";
import NetworkServer from "../pages/service_Pages/NetworkServer ";
import CloudSolution from "../pages/service_Pages/CloudSolution";
import BlogPage from "../pages/BlogPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import KvkkPolicy from "../pages/KvkkPolicy";
import AdminLogin from "../components/AdminLogin";
import AdminRegister from "../components/AdminRegister";
import AdminPanel from "../components/AdminPanel";
import PrivateRouter from "./PrivateRouter";
import RegisterPrivateRouter from "./RegisterPrivateRouter";
import BlogPageDetails from "../pages/BlogPageDetails";
import AdminForgotModal from "../components/AdminForgotModal";
import AdminForgotModalPrivate from "./AdminForgotModalPrivate";
import PositionsPage from "../pages/PositionsPage";

const routes = [
  { path: "/", exact: true, element: <Homepage /> },
  { path: "/contact_us", exact: true, element: <Contactus /> },
  { path: "/career", exact: true, element: <Careerpage /> },
  { path: "/about", exact: true, element: <About /> },
  { path: "/web_solution", exact: true, element: <WebSolution /> },
  { path: "/software_dev", exact: true, element: <SoftwareDev /> },
  { path: "/optimization", exact: true, element: <Optimization /> },
  { path: "/ssl_service", exact: true, element: <SslService /> },
  { path: "/email_solution", exact: true, element: <EmailSolution /> },
  { path: "/android_app", exact: true, element: <AndroidApp /> },
  { path: "/ios_app", exact: true, element: <IosApp /> },
  { path: "/ecommerce", exact: true, element: <Ecommerce /> },
  { path: "/corporateid", exact: true, element: <CorporateId /> },
  { path: "/social_media", exact: true, element: <SocialMedia /> },
  { path: "/google_ads", exact: true, element: <GoogleAds /> },
  { path: "/google_business", exact: true, element: <GoogleBusiness /> },
  { path: "/support_care", exact: true, element: <SupportCare /> },
  { path: "/network_server", exact: true, element: <NetworkServer /> },
  { path: "/cloud_solution", exact: true, element: <CloudSolution /> },
  { path: "/blog_page", exact: true, element: <BlogPage /> },
  { path: "/privacy_policy", exact: true, element: <PrivacyPolicy /> },
  { path: "/kvkk_policy", exact: true, element: <KvkkPolicy /> },
  { path: "/admin", exact: true, element: <AdminLogin /> },
  { path: "/blog_page/:id", exact: true, element: <BlogPageDetails /> },
  {
    path: "",
    element: <PrivateRouter />,
    children: [
      { path: "/admin_panel", element: <AdminPanel /> },
      {
        path: "",
        element: <RegisterPrivateRouter />,
        children: [{ path: "/register", element: <AdminRegister /> }],
      },
    ],
  },
  {
    path: "",
    element: <AdminForgotModalPrivate />,
    children: [
      { path: "/forgot_modal", exact: true, element: <AdminForgotModal /> },
    ],
  },
  { path: "/positions", exact: true, element: <PositionsPage /> },
];
const renderRoutes = (routes) => {
  return routes.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      element={route.element}
      exact={route.exact}
    >
      {route.children && renderRoutes(route.children)}
    </Route>
  ));
};

const Router = () => {
  return <Routes>{renderRoutes(routes)}</Routes>;
};

export default Router;
