import React, { useState } from "react";
import YukaTechLogo from "../assets/logo.png";
import { Spacer, Button } from "@chakra-ui/react";
import NavLinks from "./Navlinks";
import { Box, Image, List, ListItem, Flex } from "@chakra-ui/react";
import IonIcon from "@reacticons/ionicons";
import Language from "./Language";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleOnClose = () => setShowModal(false);
  const handleOnClick = () => {
    setShowModal(true);
    setOpen((prev) => !prev);
  };

  return (
    <Box
      className={`{ ${
        open ? "fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm " : "none"
      } }`}
      zIndex={2}
    >
      <Modal onClose={handleOnClose} visible={showModal} />
      <Box
        zIndex={2}
        width="100%"
        height={{ base: "58px", md: "90px" }}
        bgColor="whiteColor"
        position="fixed"
        top="0"
        className="bg-opacity-70 backdrop-blur-sm"
      >
        <Box className="flex items-center font-medium justify-around">
          <Box className="z-50 p-5 lg:w-auto w-full flex justify-between">
            <Link to="/">
              <Image
                width={{
                  base: "100px",
                  sm: "120px",
                  md: "150px",
                  lg: "200px",
                  xl: "200px",
                }}
                src={YukaTechLogo}
                alt="YukaTechlogo"
                className="lg:cursor-pointer"
              />
            </Link>
            <Box
              className="text-3xl lg:hidden"
              onClick={() => setOpen((prev) => !prev)}
            >
              <IonIcon name={`${open ? "close" : "menu"}`} />
            </Box>
          </Box>
          <List className="lg:flex hidden  items-center gap-2">
            <Link to="/" className="py-7 px-5 inline-block">
              {t("home_page")}
            </Link>
            <NavLinks />
            <ListItem>
              <HashLink
                to="/#reference"
                smooth={true}
                className="py-7 px-5 inline-block"
              >
                {t("references")}
              </HashLink>
            </ListItem>
            <ListItem>
              <Link to="/contact_us" className="py-7 px-5 inline-block">
                {t("contact")}
              </Link>
            </ListItem>
          </List>
          <Box className="lg:block hidden">
            <Flex>
              <Box p="4">
                <Button
                  mt={2}
                  color="whiteColor"
                  bgColor="baseColor"
                  _hover={{ bgColor: "hoverBlueColor" }}
                  width="39"
                  className=" text-white px-10 py-2 rounded-full  hover:bg-[#05063D]"
                  onClick={() => setShowModal(true)}
                >
                  {t("take_offer")}
                </Button>
              </Box>
              <Spacer />
              <Box p="5" justifyContent="space-between">
                <Language />
              </Box>
            </Flex>
          </Box>

          {/* Mobile nav*/}
          <List
            className={`{
            lg:hidden bg-white absolute  top-16  pl-4 
            duration-500 ${open ? "left-0" : "left-[-100%] "} 
        }`}
            overflowY="auto"
            height={"100vh"}
            width={{ base: "100% !important", sm: "75%", md: "50%" }}
            top="55px"
            zIndex={2}
          >
            <ListItem>
              <Link
                to="/"
                className="py-7 px-3 inline-block"
                onClick={() => setOpen((prev) => !prev)}
              >
                {t("home_page")}
              </Link>
            </ListItem>
            <NavLinks />
            <ListItem>
              <HashLink
                to="#reference"
                className="py-7 px-3 inline-block"
                onClick={() => setOpen((prev) => !prev)}
              >
                {t("references")}
              </HashLink>
            </ListItem>
            <ListItem>
              <Link to="/contact_us" className="py-7 px-3 inline-block">
                {t("contact")}
              </Link>
            </ListItem>
            <Box className="py-5">
              <Flex alignItems="center">
                <Box p="4">
                  <Button
                    color="whiteColor"
                    bgColor="baseColor"
                    _hover={{ bgColor: "hoverBlueColor" }}
                    width={{ base: "140px", sm: "110px" }}
                    className=" text-white px-10 py-2 rounded-full"
                    onClick={handleOnClick}
                  >
                    {t("take_offer")}
                  </Button>
                </Box>
                <Spacer />
                <Box display="flex" alignItems="center" marginBottom="15px">
                  <Language />
                </Box>
              </Flex>
            </Box>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
