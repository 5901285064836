import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PasswordChangeModal from "./AdminModalPassword";
import { getCookie } from "./Token";
import { removeAllCookies } from "../utils/helpers"; 

const AdminProfileWindow = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPasswordChangeModalOpen, setPasswordChangeModalOpen] =
    useState(false);

  const userDetails = useMemo(() => {
    if (isOpen) {
      const authUserCookie = getCookie("authUser");
      return authUserCookie ? JSON.parse(authUserCookie) : {};
    }
  }, [isOpen]);

  const userEmail = userDetails?.email;
  const role = userDetails?.role;
  const showAdminButton = role === "master_admin";

  const openPasswordChangeModal = useCallback(() => {
    setPasswordChangeModalOpen(true);
  }, []);

  const handleAdminAddClick = useCallback(() => {
    navigate("/register");
  }, [navigate]);


const handleExitClick = useCallback(() => {
  removeAllCookies();
  navigate("/admin");
}, [navigate]);


  if (!isOpen) {
    return null;
  }
  return (
    <Box
      position="fixed"
      top="0"
      right="0"
      bottom="0"
      width="320px"
      backgroundColor="whiteColor"
      boxShadow="-2px 0px 5px 0px rgba(0,0,0,0.1)"
      zIndex="1"
      overflowY="auto"
    >
      <IconButton
        icon={<Icon as={CloseIcon} />}
        size="sm"
        color="primaryColor"
        position="absolute"
        top="10px"
        right="10px"
        onClick={onClose}
      />
      <Text fontSize="xl" ml={7} mt={5}>
        {userEmail}
      </Text>
      <Flex>
        {showAdminButton && (
          <Button
            leftIcon={<AddIcon />}
            color="baseTealColor"
            borderColor="baseTealColor"
            variant="outline"
            mt={4}
            ml={5}
            onClick={handleAdminAddClick}
          >
            {t("add_admin")}
          </Button>
        )}
        <Button
          color="baseRedColor"
          borderColor="baseRedColor"
          variant="outline"
          mt={4}
          ml={5}
          onClick={openPasswordChangeModal}
        >
          {t("changed_password")}
        </Button>
      </Flex>
      <Button
        color="baseRedColor"
        borderColor="baseRedColor"
        variant="outline"
        mt={4}
        ml={5}
        onClick={handleExitClick}
      >
        {t("exit")}
      </Button>
      <PasswordChangeModal
        isOpen={isPasswordChangeModalOpen}
        onClose={() => setPasswordChangeModalOpen(false)}
        userEmail={userEmail}
      />
    </Box>
  );
};

export default AdminProfileWindow;
