import React, { useMemo } from "react";
import { Box, IconButton } from "@chakra-ui/react";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JobSlide from "./jobs/JobSlide";
import { jobsData } from "../utils/consts";
import Body from "./Body";
import { theme } from "../utils/theme";

const CustomPrevArrow = ({ onClick }) => (
  <IconButton
    aria-label="Previous Slide"
    icon={<ChevronLeftIcon boxSize={12} color={theme.colors.whiteColor} />}
    onClick={onClick}
    position="absolute"
    left="15px"
    top="50%"
    transform="translateY(-50%)"
    zIndex="2"
    background={theme.colors.transparent}
    _hover={{ opacity: 0.8 }}
    _focus={{ boxShadow: "none" }}
    display={{ base: "none", md: "block" }}
  />
);

const CustomNextArrow = ({ onClick }) => (
  <IconButton
    aria-label="Next Slide"
    icon={<ChevronRightIcon boxSize={12} color={theme.colors.whiteColor} />}
    onClick={onClick}
    position="absolute"
    right="15px"
    top="50%"
    transform="translateY(-50%)"
    zIndex="2"
    background={theme.colors.transparent}
    _hover={{ opacity: 0.8 }}
    _focus={{ boxShadow: "none" }}
    display={{ base: "none", md: "block" }}
  />
);

const HomeHeroSection = () => {
  const slideItems = useMemo(
    () => [
      { type: "component", content: <Body /> },
      { type: "component", content: <JobSlide data={jobsData[0]} /> },
      { type: "component", content: <JobSlide data={jobsData[1]} /> },
      { type: "component", content: <JobSlide data={jobsData[2]} /> },
      { type: "component", content: <JobSlide data={jobsData[3]} /> },
    ],
    [jobsData]
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Box marginTop={{ base: "58px", md: "90px" }} position="relative">
      <Slider {...settings}>
        {slideItems.map((item, index) => (
          <Box key={index} >
            {item.content}
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default HomeHeroSection;
