import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "1024px",
  xl: "1440px",
};
export const theme = extendTheme({
  breakpoints,
  colors: {
    buttonColor: "#081b38",
    baseRedColor: "#d63447",
    logoRedColor: "#e30612",
    whiteColor: "#FFFFFF",
    primaryColor: "#05063d",
    brandColor: "#e9f3fe",
    baseColor: "#2b2e83",
    baseBlackColor: "#000000",
    blueColor: "#1194d0",
    hoverBlueColor: "#05063D",
    borderColor: "#e2e8f0",
    baseBlueColor: "#0000FF",
    panelBgColor: "#F4F7FC",
    baseTealColor: "#008080",
    baseGreyColor: "#808080",
    buttonBlueColor: "#34549E",
    transparentColor: "rgba(0, 0, 0, 0.7)",
    jobNameTitleColors: {
      yellowColor: "#FFDE59",
      redColor: "#FF3131",
      turquoiseColor: "#5CE1E6",
      greenColor: "#00BF63",
      orangeColor: "#FF914D",
      blueColor: "#0000FF",
      purpleColor: "#800080",
      pinkColor: "#FFC0CB",
      grayColor: "#808080",
    },
    positionPageHeaderBG: "#2A2968",
    positionPageCardBG: "#F4F7FC",
    positionPageCardTypeColor: "rgb(100 116 139 / var(--tw-text-opacity, 1))",
    jobNameColor: "#047857",
    jobNameBGColor: "#ecfdf5",
    jobIconColor: "#10B981",
    transparent: "transparent",
  },
  components: {
    Text: {
      variants: {
        size1: {
          fontSize: 12,
          marginLeft: 5,
        },
        size2: {
          fontSize: 15,
          marginLeft: 30,
        },
        size3: {
          fontSize: 20,
          marginLeft: 45,
        },
        size4: {
          fontSize: 28,
          marginLeft: 50,
        },
        size5: {
          fontSize: 18,
          margin: 5,
        },
        size6: {
          fontSize: 30,
          margin: 30,
        },
        size7: {
          fontSize: 45,
          margin: 45,
        },
        size8: {
          fontSize: 58,
          margin: 50,
        },
      },
    },
    Button: {
      variants: {
        size1: {
          fontSize: 12,
          margin: 5,
          width: 100,
        },
        size2: {
          fontSize: 15,
          margin: 30,
          width: 100,
        },
        size3: {
          fontSize: 20,
          margin: 45,
          width: 150,
        },
        size4: {
          fontSize: 25,
          margin: 50,
          width: 200,
        },
      },
    },
    Link: {
      variants: {
        size5: {
          fontSize: 28,
          margin: 10,
          textAlign: "center",
          display: "grid",
        },
        size6: {
          fontSize: 32,
          margin: 30,
          textAlign: "center",
          display: "grid",
        },
        size7: {
          fontSize: 48,
          margin: 45,
          textAlign: "center",
          display: "grid",
        },
        size8: {
          fontSize: 58,
          margin: 50,
          textAlign: "center",
          display: "grid",
        },
      },
    },
  },
});
