import React from "react";
import { Box, Text, HStack, Stack, Button, Flex } from "@chakra-ui/react";
import jobBackgroundImage from "../../assets/jobs_background_image.webp";
import { theme } from "../../utils/theme";
import { useTranslation } from "react-i18next";

const JobSlide = (props) => {
  const { data, isCareer } = props;
  const { t } = useTranslation();

  return (
    <Stack
      position="relative"      
      height={"700px"}
      width="100%"
      overflow="hidden"
      backgroundImage={!isCareer ? jobBackgroundImage : ""}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      alignItems={"flex-start"}
      justifyContent={!isCareer ? "space-between" : "flex-start"}
      spacing={{ base: 4, md: 8 }}
      p={!isCareer ? { base: "1em ", md: "1em 10em 1em 10em" } : "0"}
    >
      <Flex
        w="100%"
        h="auto"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        flexWrap={{ base: "wrap", md: "nowrap" }}
      >
        <Box>
          <HStack flexWrap={{ base: "wrap", md: "nowrap" }}>
            <Text
              fontSize={!isCareer ? { base: "2xl", md: "4xl" } : "28px"}
              fontWeight="bold"
              bg={data.job_name_bg_color}
              color={!isCareer ? data.job_name_color : ""}
              p={2}
            >
              {data.job_name}
            </Text>
            <Text
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              color={data.job_name_color}
              display={isCareer ? "none" : "block"}
            >
              {t("developer")}
            </Text>
          </HStack>
          <Text
            display={isCareer ? "none" : "block"}
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="bold"
            color={data.job_name_color}
          >
            {t("wanted")}
          </Text>

          <Text
            fontSize={"28px"}
            fontWeight="bold"
            display={isCareer ? "block" : "none"}
          >
            {t("developer_wanted")}
          </Text>
        </Box>
        <Box display={!isCareer ? "block" : "none"}>
          <Text
            color={data.job_name_bg_color}
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="bold"
          >
            {data.software_language}
          </Text>
        </Box>
      </Flex>
      <Stack
        mt={!isCareer ? { base: 5, md: 10 } : { base: 2, md: 5 }}
        mb={!isCareer ? { base: 4, md: 7 } : { base: 0, md: 0 }}
        minH={"200px"}
      >
        {data.qualifications_required.map((qualification) => (
          <HStack
            key={qualification}
            color={!isCareer ? theme.colors.whiteColor : "gray.500"}
          >
            <Text
              fontWeight={"bold"}
              fontSize={!isCareer ? { base: "xl", md: "32px" } : "20px"}
            >
              {"● "}
            </Text>
            <Text
              fontSize={!isCareer ? { base: "lg", md: "2xl" } : "20px"}
              fontWeight={"normal"}
            >
              {t(qualification)}
            </Text>
          </HStack>
        ))}
      </Stack>
      <Box h={"60px"} display={isCareer ? "none" : "block"}>
        <Button
          colorScheme="red"
          size="lg"
          mt="auto"
          height={"50px"}
          borderRadius={"23px"}
          as="a"
          href="/career"
          target="_blank"
        >
          {t("apply")}
        </Button>
      </Box>
    </Stack>
  );
};

export default JobSlide;
