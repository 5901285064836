import IonIcon from "@reacticons/ionicons";
import React, { useState } from "react";
import { Box, List, ListItem, Text, Grid } from "@chakra-ui/react";
import { navbarLinkLists, contactLinkList } from "../utils/consts";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const NavLinks = () => {
  const { t } = useTranslation();
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  return (
    <>
      {navbarLinkLists.map(({ name, subMenu, sublinks }, index) => (
        <Box key={index}>
          <Box className="px-3 text-left lg:cursor-pointer group">
            <Link
              className="py-7 flex justify-between items-center lg:pr-0 pr-5"
              onClick={() => {
                heading !== name ? setHeading(name) : setHeading("");
                setSubHeading("");
              }}
            >
              {t(name)}
              <Text as="span" className="text-xl lg:mt-1 lg:hidden inline">
                <IonIcon
                  name={`${heading === name ? "chevron-up" : "chevron-down"}`}
                />
              </Text>
              <Text
                as="span"
                className="text-xl lg:mt-1 lg:ml-2 lg:block hidden group-hover:rotate-180 group-hover:-mt-2"
              >
                <IonIcon name="chevron-down" />
              </Text>
            </Link>
            {subMenu && name !== "corporate" ? (
              <Box>
                <Box
                  className="absolute top-20 hidden group-hover:lg:block hover:lg:block"
                  left={10}
                  minW={"95%"}
                  width={{ lg: "70%" }}
                >
                  <Box className="py-2" />
                  <Grid
                    className="bg-white p-5 grid grid-cols-4"
                    templateColumns="repeat(4, 1fr)"
                  >
                    {sublinks.map(({ headTitle, subLink, link }, index) => (
                      <Box key={index}>
                        <Box>
                          <Link to={link} className="text-md font-semibold">
                            {t(headTitle)}
                          </Link>
                          <Box
                            backgroundColor="buttonColor"
                            width="160px"
                            borderTop="2px dotted"
                            borderColor="whiteColor"
                          />
                          {subLink.map(({ name, link }, index) => (
                            <List
                              key={index}
                              className="text-s text-gray-600 my-2.5"
                            >
                              <Link
                                to={link}
                                className="hover:text-baseBlueColor"
                              >
                                {t(name)}
                              </Link>
                            </List>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Box>
                <Box
                  className="absolute top-20 hidden group-hover:lg:block hover:lg:block"
                  left={20}
                  minW={"90%"}
                  width={{ lg: "70%" }}
                >
                  <Box className="py-2"></Box>
                  <Grid
                    className="bg-white p-5 grid grid-cols-4"
                    templateColumns="repeat(2, 2fr)"
                  >
                    <Box style={{ paddingLeft: "20px" }}>
                      <Text className="text-md font-semibold">
                        {t("who_us")}
                      </Text>
                      <Box
                        backgroundColor="buttonColor"
                        width="160px"
                        borderTop="2px dotted"
                        borderColor="whiteColor"
                      />
                      <Box>
                        <Text
                          className="text-s text-gray-600 my-2.5"
                          width="400px"
                          alignItems="center"
                        >
                          {t("corporate_who_us")}
                        </Text>
                        <Link className="hover:text-baseBlueColor" to="/about">
                          {t("read_more")} <ExternalLinkIcon mx="2px" />
                        </Link>
                      </Box>
                    </Box>
                    <Box>
                      <Text className="text-md font-semibold">
                        {t("links")}
                      </Text>
                      <Box
                        backgroundColor="buttonColor"
                        width="160px"
                        borderTop="2px dotted"
                        borderColor="whiteColor"
                      />
                      <Box>
                        <List className="text-s text-gray-600 my-2.5">
                          {contactLinkList.map(({ title, link }, index) => (
                            <ListItem
                              key={index}
                              _hover={{ color: "baseBlueColor" }}
                            >
                              <Link
                                to={link}
                                onClick={() =>
                                  localStorage.removeItem("selectedJobName")
                                }
                              >
                                {t(title)}
                              </Link>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
          {/*Mobile menu*/}
          <Box
            className={`
            ${heading === name ? "lg:hidden" : "hidden"}
          `}
          >
            {subMenu && name !== "corporate" ? (
              <div>
                {sublinks.map(({ headTitle, subLink }, index) => (
                  <Box key={index}>
                    <Box>
                      <Link
                        onClick={() => {
                          if (subHeading !== headTitle) {
                            setSubHeading(headTitle);
                          } else {
                            setSubHeading("");
                          }
                        }}
                        className="py-4 pl-7 font-semibold lg:pr-0 pr-5 flex justify-between items-center "
                      >
                        {t(headTitle)}
                        <Text
                          as="span"
                          className="text-xl lg:mt-1 lg:ml-2 inline"
                        >
                          <IonIcon
                            name={`${
                              subHeading === headTitle
                                ? "chevron-up"
                                : "chevron-down"
                            }`}
                          />
                        </Text>
                      </Link>
                      <Box
                        className={`${
                          subHeading === headTitle ? "lg:hidden" : "hidden"
                        }`}
                      >
                        {subLink.map(({ name, link }, index) => (
                          <List key={index} className="py-3 pl-14">
                            <Link to={link}>{t(name)}</Link>
                          </List>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </div>
            ) : (
              <div>
                {sublinks.map(({ headTitle, link }, index) => (
                  <Box key={index}>
                    <Box>
                      <Link
                        to={link}
                        className="py-4 pl-7 font-semibold lg:pr-0 pr-5 flex justify-between items-center "
                      >
                        {t(headTitle)}
                      </Link>
                      {contactLinkList.map(({ title, link }, index) => (
                        <ListItem
                          key={index}
                          _hover={{ color: "baseBlueColor" }}
                        >
                          <Link
                            className="py-4 pl-7 font-semibold lg:pr-0 pr-5 flex justify-between items-center "
                            to={link}
                          >
                            {t(title)}
                          </Link>
                        </ListItem>
                      ))}
                    </Box>
                  </Box>
                ))}
              </div>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default NavLinks;
