import { apiClient } from "../api/apiClient";

export const registerAdmin = async ({ values, authToken }) => {
  const formData = new FormData();
  formData.append("email", values.email);
  formData.append("password", values.password);

  const response = await apiClient.post("register", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
};

export const adminLogin = async ({ values }) => {
  const formData = new FormData();
  formData.append("email", values.email);
  formData.append("password", values.password);
  const response = await apiClient.post("admin-login", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const adminForgotModal = async (values) => {
  const formData = new FormData();
  formData.append("token", values.token);
  formData.append("userEmail",values.email);
  formData.append("newPassword", values.newPassword);
  formData.append("repeatNewPassword", values.repeatNewPassword);
  const response = await apiClient.post("forgot-modal", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const adminForgotPassword = async (values) => {
  const formData = new FormData();
  formData.append("email", values.email);
  const response = await apiClient.post("forgot-password", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data; 
};

export const adminModalPassword = async (values) => {
  const formData = new FormData();
  formData.append("userEmail", values.userEmail);
  formData.append("currentPassword", values.currentPassword);
  formData.append("newPassword", values.newPassword);
  formData.append("repeatNewPassword",values.repeatNewPassword);
  const response = await apiClient.post("change-password", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const combinedAnnouncements = async (page, perPage) => {
  const response = await apiClient.get(
    `/CombinedAnnouncements.php?page=${page}&perPage=${perPage}`
  );
  return response.data;
};

export const deleteAnnouncement = async (id) => {
  await apiClient.delete(`/DeleteAnnouncement.php?id=${id}`);
};

export const createAnnouncement = async (announcementData) => {
  const formData = new FormData();
  formData.append("title", announcementData.title);
  formData.append("content", announcementData.content);
  formData.append("date", announcementData.date);
  const response = await apiClient.post("new-announcements", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const updateAnnouncement = async (announcementData) => {
  const formData = new FormData();
  for (const key in announcementData) {
    formData.append(key, announcementData[key]);
  }
  await apiClient.post("update-announcements", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
