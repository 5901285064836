import { Flex, Grid, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { Button } from "react-scroll";
import { combinedAnnouncements } from "../api/apiService";
import blogCardBackground from "../assets/blogCardBackground.jpg";
import { htmlTagRegex, perPage } from "../utils/consts";
import { extractImageFromHTML } from "../utils/helpers";
import BlogCardItem from "./BlogCardItem";

function cleanHtmlTags(html) {
  return html.replace(htmlTagRegex, "");
}

const fetchAnnouncements = async ({ pageParam = 1 }) => {
  try {
    const { data } = await combinedAnnouncements(pageParam, perPage);
    const { announcementList, totalAnnouncements } = data;
    return {
      announcements: announcementList,
      nextPage: pageParam + 1,
      totalPages: Math.ceil(totalAnnouncements / perPage),
    };
  } catch (error) {
    throw new Error("Error fetching announcements");
  }
};

const BlogCard = () => {
  const { t } = useTranslation();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery("announcements", fetchAnnouncements, {
      getNextPageParam: (lastPage) =>
        lastPage.nextPage <= lastPage.totalPages
          ? lastPage.nextPage
          : undefined,
    });

  const uncleanedAnnouncements = useMemo(() => {
    if (data) {
      const allAnnouncements = data.pages.flatMap((page) => page.announcements);
      return allAnnouncements;
    }
    return [];
  }, [data]);

  const cleanedAnnouncements = useMemo(() => {
    if (uncleanedAnnouncements.length) {
      const cleanedData = uncleanedAnnouncements.map((announcement) => ({
        ...announcement,
        content: cleanHtmlTags(announcement.content),
        imageUrl:
          extractImageFromHTML(announcement.content) || blogCardBackground,
      }));
      return cleanedData;
    }
    return [];
  }, [uncleanedAnnouncements]);

  return (
    <>
      {cleanedAnnouncements.length > 0 ? (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr 1fr",
          }}
          gap={6}
        >
          {cleanedAnnouncements.map((announcement, index) => (
            <BlogCardItem
              key={announcement.id}
              announcement={announcement}
              uncleanedContent={uncleanedAnnouncements[index].content}
            />
          ))}
        </Grid>
      ) : (
        <Text
          textAlign="center"
          mt="30px"
          mb="50px"
          fontSize="18px"
          fontWeight="600"
        >
          {t("no_announcements_available")}
        </Text>
      )}
      {hasNextPage && (
        <Flex
          justifyContent="center"
          mt="50px"
          mb="50px"
          fontSize="18px"
          fontWeight="600"
        >
          <Button
            onClick={fetchNextPage}
            isLoading={isFetchingNextPage}
            loadingText={t("loading_more")}
            disabled={isFetchingNextPage}
          >
            {isFetchingNextPage ? t("loading_more") : t("load_more")}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default BlogCard;
